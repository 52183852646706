
import React, {
    ReactElement, useEffect, useState,
} from 'react';
import {
    createBrowserRouter, RouteObject, RouterProvider,
} from 'react-router-dom';
import Loadable from '@loadable/component';
import NotFound from '../pages/404';

const Loading = () => (<div>加载中...</div>);

const AsyncComponent = ({ Comp }) => {
    const [element, setElement] = useState<ReactElement>();
    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        const Ele = Loadable(Comp);
        setElement(<Ele />);
    }, [Comp]);

    if (!element) {
        return <Loading />;
    }

    return element;
};
const routeList: RouteObject[] = [
  {
    "path": "/",
    "children": [
      {
        "path": "",
        "element": <AsyncComponent Comp={() => import('../pages/index/index')} />
      },
      {
        "path": "signup",
        "children": [
          {
            "path": "",
            "element": <AsyncComponent Comp={() => import('../pages/signup/index')} />
          }
        ]
      },
      {
        "path": "register",
        "children": [
          {
            "path": "",
            "element": <AsyncComponent Comp={() => import('../pages/register/index')} />
          }
        ]
      },
      {
        "path": "profile",
        "children": [
          {
            "path": "",
            "element": <AsyncComponent Comp={() => import('../pages/profile/index')} />
          }
        ]
      },
      {
        "path": "login",
        "children": [
          {
            "path": "",
            "element": <AsyncComponent Comp={() => import('../pages/login/index')} />
          }
        ],
        "element": <AsyncComponent Comp={() => import('../pages/login/_layout/index')} />
      },
      {
        "path": "forget",
        "children": [
          {
            "path": "",
            "element": <AsyncComponent Comp={() => import('../pages/forget/index')} />
          }
        ]
      },
      {
        "path": "EnterpriseUserManagement",
        "children": [
          {
            "path": "registrationApproval",
            "children": [
              {
                "path": "",
                "element": <AsyncComponent Comp={() => import('../pages/EnterpriseUserManagement/registrationApproval/index')} />
              },
              {
                "path": "detail",
                "children": [
                  {
                    "path": "",
                    "element": <AsyncComponent Comp={() => import('../pages/EnterpriseUserManagement/registrationApproval/detail/index')} />
                  }
                ]
              }
            ]
          },
          {
            "path": "changeReview",
            "children": [
              {
                "path": "",
                "element": <AsyncComponent Comp={() => import('../pages/EnterpriseUserManagement/changeReview/index')} />
              },
              {
                "path": "detail",
                "children": [
                  {
                    "path": "",
                    "element": <AsyncComponent Comp={() => import('../pages/EnterpriseUserManagement/changeReview/detail/index')} />
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        "path": "agentReset",
        "children": [
          {
            "path": "",
            "element": <AsyncComponent Comp={() => import('../pages/agentReset/index')} />
          }
        ]
      }
    ],
    "element": <AsyncComponent Comp={() => import('../pages/_layout/index')} />
  }
];
routeList.push(
    {
        "path": "*",
        "element": <NotFound />,
    }
);
const router = createBrowserRouter(routeList);

const Routers = () => <RouterProvider router={router} />;

export default Routers;
        